<template>
  <b-card>
    <FormBuilder
      :diff-only="false"
      label="Highlight"
      update
      :data="$store.getters['highlight/detailData']"
      :fields="fields"
      :loading-detail="$store.state.highlight.loadingDetail"
      :loading-submit="$store.state.highlight.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
          minLength: 5,
        },
        {
          key: 'countries',
          option_key: 'id',
          label: 'Countries',
          type: 'multiple',
          actionSearch: 'country/search',
          text: 'text',
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
          type: 'number',
        },
        {
          key: 'imgUrl',
          label: 'Image',
          type: 'image',
        },
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
        },
        {
          key: 'url',
          label: 'Url',
          type: 'url',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('highlight/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('highlight/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('highlight/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

  <style scoped>

  </style>
